@font-face {
    font-family: 'NotoSans';
    font-weight: 400;
    src: url('././statics/fonts/Noto_Sans/NotoSans-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'NotoSans';
    font-weight: 500;
    src: url('././statics/fonts/Noto_Sans/NotoSans-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'NotoSans';
    font-weight: 600;
    src: url('././statics/fonts/Noto_Sans/NotoSans-Bold.ttf') format('truetype');
}

.App {
    text-align: center;
    font-family: 'NotoSans';
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    font-family: 'NotoSans';
}

.App-link {
    color: #00657e;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
